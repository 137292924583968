export default class Environment {
  static isInDevelopmentMode(): boolean {
    return !process.env.NODE_ENV || process.env.NODE_ENV !== "production";
  }

  // Used for setting the throttle delay duration for the useAsyncRequest hook, this allows us to have a different value in a test environment
  static ApiThrottleDelayMs(): number {
    return process.env.API_THROTTLE_DELAY_MS ? parseInt(process.env.API_THROTTLE_DELAY_MS) : 1000;
  }
}
