import React, { useCallback, useEffect, useState } from "react";
import type { SxProps, Theme } from "@mui/material";
import { Typography, Grid } from "@mui/material";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import type { EventListener } from "shared/EventListener";
import type { EventPaymentFailure, EventPaymentSuccessful } from "shared/PaymentEvents";
import { isZuoraLibrary, type ZuoraCallback, type ZuoraClientErrorMessageCallback } from "shared/ZuoraLibraryTypes";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import type { AdvancedDigitalSignature, ProductType } from "client/api/PurchaseApi";
import { checkout } from "client/api/PurchaseApi";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import {
  CustomErrorAlert,
  ErrorMessageContactSupport,
  ErrorMessageTryAgainOrContactSupport,
} from "components/alert/CustomErrorAlert";
import { CheckoutPrimaryButton, CheckoutSecondaryButton } from "areas/checkout/components/CheckoutButtons";
import { isDropdownAutocompleteOption } from "areas/checkout/components/CheckoutDropdownInput";
import { CheckoutStepLayout } from "areas/purchasing/components/CheckoutStepLayout";
import type { CheckoutCompanyForm, CheckoutIndividualForm } from "./CheckoutPersonalDetailsStep";
import { ZuoraHostedPaymentFormContainer } from "./CheckoutZuoraIframe";
import type { ZuoraServerSideError } from "./CheckoutZuoraServerSideErrors";
import { CheckoutZuoraServerSideErrors } from "./CheckoutZuoraServerSideErrors";

interface CheckoutPaymentStepProps {
  onBack: () => void;
  onOrderComplete: () => void;
  orderSummary: React.ReactElement;
  billingProduct: ProductType;
  personalStepDetails: CheckoutCompanyForm | CheckoutIndividualForm;
  isZuoraLibraryReady: boolean;
  sx?: SxProps<Theme>;
}

const PaymentStepFrame = ({
  children,
  orderSummary,
  sx,
}: {
  children: React.ReactElement;
  orderSummary: React.ReactElement;
  sx?: SxProps<Theme>;
}) => (
  <CheckoutStepLayout orderSummary={orderSummary} sx={sx} testId={"checkout-payment-step"}>
    <Typography variant={"h5"} fontWeight={"600"}>
      Payment Details
    </Typography>
    {children}
  </CheckoutStepLayout>
);

export function CheckoutPaymentStep(props: CheckoutPaymentStepProps) {
  const { onBack, onOrderComplete, personalStepDetails, billingProduct, orderSummary, isZuoraLibraryReady, sx } = props;
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState<ZuoraServerSideError[] | undefined>(undefined);
  const [loadZuoraIframeError, setLoadZuoraIframeError] = useState<Error | undefined>(undefined);
  const [orderError, setOrderError] = useState<Error | undefined>(undefined);
  const [attempt, setAttempt] = useState(0);

  const { entity: licenseOrSubscription } = useEntity<CloudSubscriptionDto | ServerLicenseDto>();

  const submitPayment = () => {
    setIsSubmitEnabled(false);
    setServerSideErrors(undefined);
    const Z = window.Z;
    if (isZuoraLibrary(Z)) {
      Z.submit();
    }
  };

  const onPaymentComplete = useCallback(
    async (advancedDigitalSignature: AdvancedDigitalSignature) => {
      const req = (() => {
        const form = personalStepDetails;
        const businessAccount = form.purchasingAs === "company";

        if (businessAccount) {
          const billToContact = {
            firstName: form.billingContact.firstName,
            lastName: form.billingContact.lastName,
            email: form.billingContact.email,
            // Note: uses the company address
            address1: form.companyContact.streetAddress,
            country: form.companyContact.country?.value || "",
            city: form.companyContact.city,
            state: isDropdownAutocompleteOption(form.companyContact.state)
              ? form.companyContact.state.value
              : form.companyContact.state ?? "",
            postalCode: form.companyContact.postcode,
          };
          const companyName = form.companyContact.name;
          const taxId = form.taxId;

          return {
            billingProduct,
            serial: licenseOrSubscription?.serial ?? "",
            businessAccount,
            billToContact,
            companyName,
            taxId,
            advancedDigitalSignature,
          };
        } else {
          const billToContact = {
            firstName: form.billingContact.firstName,
            lastName: form.billingContact.lastName,
            email: form.billingContact.email,
            address1: form.billingContact.streetAddress,
            country: form.billingContact.country?.value || "",
            city: form.billingContact.city,
            state: isDropdownAutocompleteOption(form.billingContact.state)
              ? form.billingContact.state.value
              : form.billingContact.state ?? "",
            postalCode: form.billingContact.postcode,
          };

          return {
            billingProduct,
            serial: licenseOrSubscription?.serial ?? "",
            businessAccount,
            billToContact,
            advancedDigitalSignature,
          };
        }
      })();

      try {
        await checkout(req);
      } catch (e) {
        if (e instanceof Error) {
          setOrderError(e);
        }
        return;
      }

      onOrderComplete();
    },
    [billingProduct, licenseOrSubscription?.serial, onOrderComplete, personalStepDetails]
  );

  const zuoraLoadEvent = () => {
    setIsSubmitEnabled(true);
  };

  /** Handles invalid request to get the Zuora Hosted Payment Page, e.g. invalid signature provided */
  const zuoraCallback = useCallback<ZuoraCallback>((response) => {
    if (!response.success) {
      const e = new Error("Error loading payment form.");
      setLoadZuoraIframeError(e);
      return;
    }
    setLoadZuoraIframeError(undefined);
  }, []);

  /** Respond to Zuora Hosted Payment Page client error */
  const clientErrorMessageCallback = useCallback<ZuoraClientErrorMessageCallback>(
    (key, code, message, rawGatewayInfo) => {
      // error encountered, so re-enable submit
      setIsSubmitEnabled(true);

      const errorMessage = message;
      // Note: error messages can optionally be customized/overwritten here

      const Z = window.Z;
      if (isZuoraLibrary(Z)) {
        // send the error message to the Hosted Payment Page to be displayed
        Z.sendErrorMessageToHpm(key, errorMessage);
      }
    },
    []
  );

  /**
   * Display errors outside the iframe, errors this may receive include an invalid card number, card expiry in the past, etc
   * @param params
   */
  const serverErrorHandler = (params: string) => {
    const searchParams = new URLSearchParams(params);
    const errorCode = searchParams.get("errorCode");
    const errorField = searchParams.get("errorField");
    const errorMessage = searchParams.get("errorMessage");
    const errors = [];
    if (!(errorCode || errorMessage)) {
      const catchAllError = {
        key: "catchAll",
        message: "An error occurred while processing your payment. You haven't been charged, please try again.",
      };
      errors.push(catchAllError);
    }

    if (errorCode || errorMessage) {
      const error = {
        key: "error",
        code: errorCode ?? undefined,
        field: errorField ?? undefined,
        message: errorMessage ?? "",
      };
      errors.push(error);
    }

    setServerSideErrors(errors);
  };

  /**
   * After render, within the Zuora Hosted Payment Page, handle server-side errors forwarded from the Zuora Callback page
   * @param _params The forwarded query parameters
   */
  const afterRenderServerErrorMessageCallback = (_params: string) => {
    // Note: this could parse errors from searchParams and use Z.sendErrorMessageToHpm to display errors within the iframe
  };

  // Handle paymentSuccessful event emitted from ZuoraCallbackPage
  const paymentSuccessful: EventListener<EventPaymentSuccessful> = useCallback(
    (e) => {
      const {
        tenantId,
        token,
        timestamp,
        pageId,
        refId,
        signature,
        fieldPassthrough1,
        fieldPassthrough2,
        fieldPassthrough3,
        fieldPassthrough4,
        fieldPassthrough5,
      } = e.detail;

      const advancedDigitalSignature = {
        tenantId,
        token,
        timestamp,
        pageId,
        refId,
        signature,
        fieldPassthrough1,
        fieldPassthrough2,
        fieldPassthrough3,
        fieldPassthrough4,
        fieldPassthrough5,
      };

      onPaymentComplete(advancedDigitalSignature);
    },
    [onPaymentComplete]
  );

  // Handle paymentFailure event emitted from ZuoraCallbackPage
  const paymentFailure: EventListener<EventPaymentFailure> = useCallback((e) => {
    const params = e.detail.searchParams;
    // display errors outside iframe
    serverErrorHandler(params);
    // update the attempt to render the Hosted Payment Page form again
    setAttempt((prevState) => {
      return prevState + 1;
    });
    // re-enable submit button
    setIsSubmitEnabled(true);
    // display server errors after iframe render
    const Z = window.Z;
    if (isZuoraLibrary(Z)) {
      Z.runAfterRender(() => afterRenderServerErrorMessageCallback(params));
    }
  }, []);

  useEffect(() => {
    if (isZuoraLibraryReady) {
      const Z = window.Z;
      if (isZuoraLibrary(Z)) {
        Z.setEventHandler("onloadCallback", zuoraLoadEvent);
      }
    }
  }, [isZuoraLibraryReady]);

  if (orderError) {
    return <CustomErrorAlert title="Error processing your order" message={<ErrorMessageContactSupport />} />;
  }

  if (loadZuoraIframeError) {
    return <CustomErrorAlert title="Error loading payment form" message={<ErrorMessageTryAgainOrContactSupport />} />;
  }

  return (
    <PaymentStepFrame orderSummary={orderSummary} sx={sx}>
      <>
        <CheckoutZuoraServerSideErrors errors={serverSideErrors} />
        <ZuoraHostedPaymentFormContainer
          isZuoraLibraryReady={isZuoraLibraryReady}
          zuoraCallback={zuoraCallback}
          clientErrorMessageCallback={clientErrorMessageCallback}
          paymentSuccessful={paymentSuccessful}
          paymentFailure={paymentFailure}
          attempt={attempt}
        />
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item md={6}>
            <CheckoutSecondaryButton disableElevation variant="contained" onClick={onBack}>
              Return to information
            </CheckoutSecondaryButton>
          </Grid>
          <Grid item md={6} textAlign={"right"}>
            <CheckoutPrimaryButton
              disableElevation
              variant="contained"
              type="submit"
              onClick={submitPayment}
              disabled={!isSubmitEnabled}
            >
              Subscribe
            </CheckoutPrimaryButton>
          </Grid>
        </Grid>
      </>
    </PaymentStepFrame>
  );
}
