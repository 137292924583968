import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button } from "@mui/material";
import type { DateTime } from "luxon";
import { DateText } from "@cpt/components";
import { useFeatureFlag } from "contexts/ConfigurationProvider/ConfigurationProvider";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import {
  getUpgradePlanForPriceCode,
  IsStripeSubscriptionLicensingChannel,
  isTrialLicensingChannel,
} from "utils/CloudSubscriptionLicensingChannelTypeHelpers";
import type { CloudSubscriptionDto, CloudLicensingChannel } from "client/api/CloudSubscriptionApi";
import { UpgradePlan } from "client/api/UpgradePlan";
import { GreenButton } from "components/Button/GreenButton";
import { useContactSalesDialog } from "components/Dialogs/ContactSalesDialog/ContactSalesDialogProvider";
import type { ContactSalesReason } from "components/Dialogs/ContactSalesDialog/ContactSalesReasons";
import { StripeCheckoutButton } from "components/StripeCheckoutButton/StripeCheckoutButton";
import { PurchasingRoutesMap } from "areas/purchasing/PurchasingRoutesMap";
import {
  CloudSubscriptionCancelPlanDialog,
  CloudSubscriptionCancelPlanDialogText,
} from "../CloudSubscriptionCancelPlanDialog";
import { ConfirmStripeUpgradeDialog } from "./ConfirmStripeUpgradeDialog";

interface AllowedPlanChange {
  currentPlan: UpgradePlan;
  targetPlan: UpgradePlan;
  action: UpgradeActions;
}

const getAllowedPlanChange = (currentPlan: UpgradePlan, targetPlan: UpgradePlan): AllowedPlanChange | undefined => {
  const allowedPlanChanges: AllowedPlanChange[] = [
    {
      currentPlan: UpgradePlan.Trial,
      targetPlan: UpgradePlan.Cloud_Annually_Starter,
      action: "self-service-purchase",
    },
  ];
  return allowedPlanChanges.find((x) => x.currentPlan === currentPlan && x.targetPlan === targetPlan);
};

export function getUpgradeAction(targetPlan: UpgradePlan, licensingChannel: CloudLicensingChannel): UpgradeActions {
  let currentPlan: UpgradePlan | undefined = undefined;

  if (IsStripeSubscriptionLicensingChannel(licensingChannel)) {
    if (licensingChannel.status === "canceled") return "contact-sales";
    if (licensingChannel.priceCode == targetPlan) return "no-change";
    if (licensingChannel.priceCode) {
      currentPlan = getUpgradePlanForPriceCode(licensingChannel.priceCode.toLowerCase());
    }
  } else if (isTrialLicensingChannel(licensingChannel)) {
    currentPlan = UpgradePlan.Trial;
  }

  if (currentPlan) {
    const planChange = getAllowedPlanChange(currentPlan, targetPlan);
    if (planChange) {
      return planChange.action;
    }
  }

  return "contact-sales";
}

export type UpgradeActions = "self-service-purchase" | "self-service-upgrade" | "contact-sales" | "no-change";

export type PricingCardActionProps = {
  cloudSubscriptionId: string;
  action: UpgradeActions;
  targetPlan: UpgradePlan;
  targetPlanName: string;
  projectQuantity: string;
  nextRenewalDate?: DateTime;
};

export function PricingCardAction(props: PricingCardActionProps) {
  const { cloudSubscriptionId, action, targetPlan, targetPlanName, nextRenewalDate, projectQuantity } = props;

  const { showDialog: showContactSalesDialog } = useContactSalesDialog();
  const [showUpgradeDialog, setShowUpgradeDialog] = useState<boolean>(false);
  const useZuoraCheckout = useFeatureFlag("ZuoraCheckout");
  const navigate = useNavigate();

  const subscribeButton = (
    <>
      {useZuoraCheckout ? (
        <GreenButton
          variant="contained"
          size="large"
          disableElevation
          sx={{ textTransform: "none", width: "100%", marginTop: "0.75rem", backgroundColor: "#00B065" }}
          onClick={() =>
            navigate({
              pathname: PurchasingRoutesMap.payment,
              search: `subscriptionId=${cloudSubscriptionId}&subscriptionType=cloud`,
            })
          }
          endIcon={<ChevronRightIcon />}
        >
          Subscribe
        </GreenButton>
      ) : (
        <StripeCheckoutButton
          Button={({ onClick }) => (
            <GreenButton
              variant="contained"
              size="large"
              disableElevation
              sx={{ textTransform: "none", width: "100%", marginTop: "0.75rem", backgroundColor: "#00B065" }}
              onClick={onClick}
              endIcon={<ChevronRightIcon />}
            >
              Subscribe via Stripe
            </GreenButton>
          )}
          productType="CloudSubscription"
          productId={cloudSubscriptionId}
          targetPlan={targetPlan}
        />
      )}
    </>
  );

  const upgradeButton = (
    <>
      <GreenButton
        variant="contained"
        onClick={() => setShowUpgradeDialog(true)}
        size="large"
        disableElevation
        sx={{ textTransform: "none", width: "100%", marginTop: "0.75rem", backgroundColor: "#00B065" }}
        endIcon={<ChevronRightIcon />}
      >
        Upgrade via Stripe
      </GreenButton>
      <ConfirmStripeUpgradeDialog
        open={showUpgradeDialog}
        setOpen={setShowUpgradeDialog}
        productName={targetPlanName}
        cloudSubscriptionId={cloudSubscriptionId}
        targetPlan={targetPlan}
        varyByTarget={false}
        nextRenewalDate={nextRenewalDate}
      />
    </>
  );

  const contactSalesButton = (targetPlan: UpgradePlan) => {
    let reason: ContactSalesReason = "modify-subscription";
    switch (targetPlan) {
      case UpgradePlan.Cloud_Annually_Starter:
        reason = "starter";
        break;
      case UpgradePlan.Cloud_Annually_Professional:
        reason = "professional";
        break;
      case UpgradePlan.Cloud_Annually_Enterprise:
        reason = "enterprise";
        break;
    }
    return (
      <>
        <GreenButton
          variant="contained"
          onClick={() => showContactSalesDialog({ reason, projectQuantity })}
          size="large"
          disableElevation
          sx={{ textTransform: "none", width: "100%", marginTop: "0.75rem", backgroundColor: "#00B065" }}
        >
          Contact Sales
        </GreenButton>
      </>
    );
  };

  const CancelButton = () => {
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const { entity: cloudSubscription } = useEntity<CloudSubscriptionDto>();

    switch (cloudSubscription?.licensingChannel.channelType) {
      case "StripeSubscriptionCloudSubscriptionLicensingChannelDto":
        return (
          <>
            <Button
              variant="outlined"
              size="large"
              disableElevation
              sx={{ width: "100%", marginTop: "0.75rem" }}
              onClick={() => setShowCancelDialog(true)}
            >
              Cancel
            </Button>
            <CloudSubscriptionCancelPlanDialog
              open={showCancelDialog}
              onClose={() => setShowCancelDialog(false)}
              cloudSubscription={cloudSubscription}
            >
              <span>
                If you cancel now, you can use your subscription until{" "}
                <DateText date={cloudSubscription.licensingChannel.nextRenewalDate} fontWeight="bold" />. We'll then
                automatically deactivate the plan and put secure backups of your data into cold-storage. After 90 days,
                your data will be automatically deleted. If you want your data deleted sooner, email{" "}
                <a href="mailto:support@octopus.com">support@octopus.com</a> after you've deactivated your instance, and
                we'll delete your data immediately.
              </span>
            </CloudSubscriptionCancelPlanDialog>
          </>
        );
      case "ImportedTrialCloudSubscriptionLicensingChannelDto":
        return (
          <>
            <Button
              variant="outlined"
              size="large"
              disableElevation
              sx={{ width: "100%", marginTop: "0.75rem" }}
              onClick={() => setShowCancelDialog(true)}
            >
              Cancel
            </Button>
            <CloudSubscriptionCancelPlanDialog
              open={showCancelDialog}
              onClose={() => setShowCancelDialog(false)}
              cloudSubscription={cloudSubscription}
            >
              <CloudSubscriptionCancelPlanDialogText
                channelType={cloudSubscription.licensingChannel.channelType}
                endDate={cloudSubscription.licensingChannel.expiryDate}
              />
            </CloudSubscriptionCancelPlanDialog>
          </>
        );
      case "TrialCloudSubscriptionLicensingChannelDto":
        return (
          <>
            <Button
              variant="outlined"
              size="large"
              disableElevation
              sx={{ width: "100%", marginTop: "0.75rem" }}
              onClick={() => setShowCancelDialog(true)}
            >
              Cancel
            </Button>
            <CloudSubscriptionCancelPlanDialog
              open={showCancelDialog}
              onClose={() => setShowCancelDialog(false)}
              cloudSubscription={cloudSubscription}
            >
              <CloudSubscriptionCancelPlanDialogText
                channelType={cloudSubscription.licensingChannel.channelType}
                endDate={cloudSubscription.licensingChannel.expiryDate}
              />
            </CloudSubscriptionCancelPlanDialog>
          </>
        );

      default:
        return (
          <Button
            variant="outlined"
            size="large"
            disableElevation
            sx={{ width: "100%", marginTop: "0.75rem" }}
            onClick={() => showContactSalesDialog({ reason: "modify-subscription" })}
          >
            Cancel
          </Button>
        );
    }
  };

  let button = <></>;
  switch (action) {
    case "self-service-purchase":
      button = subscribeButton;
      break;
    case "self-service-upgrade":
      button = upgradeButton;
      break;
    case "contact-sales":
      button = contactSalesButton(targetPlan);
      break;
    case "no-change":
      button = <CancelButton />;
      break;
  }

  return button;
}
