import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { OpenInNew } from "@mui/icons-material";
import { Box, CircularProgress, Link, Stack, Typography } from "@mui/material";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import { usePolling } from "hooks/usePolling";
import { getHostname } from "utils/formatters/DnsFormatters";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { NotFoundPage } from "pages/NotFoundPage";
import { CheckoutPrimaryButton } from "areas/checkout/components/CheckoutButtons";
import { TentacleHeartIcon } from "areas/checkout/components/TentacleHeartIcon";
import { ServerLicensesRoutesMap } from "areas/serverLicenses/ServerLicensesRoutesMap";
import { SelfFetchingLicenseKeyView } from "areas/serverLicenses/components/LicenseKeyView";

interface CheckoutCompleteInputProps {
  subscriptionId?: string;
  subscriptionType?: "cloud" | "selfhosted";
}

export function CheckoutComplete(props: CheckoutCompleteInputProps) {
  const { userInfo } = useUserContext();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { entity: license } = useEntity<ServerLicenseDto>();
  const { entity: cloudSubscription } = useEntity<CloudSubscriptionDto>();
  const { subscriptionId, subscriptionType } = props;
  const { hasPermissionForAsset } = useUserContext();

  const canViewLicenseKeyXml = hasPermissionForAsset(
    RbacPermissions.ServerLicense.ViewLicenseKeyXml,
    license?.id,
    license?.subscriptionGroup?.id
  );
  // TODO: Replace with api call to check fulfillment status and get updated license xml/cloud instance link
  const fakeFunction = async () => {
    setTimeout(function () {
      console.log("Fake function executed");
      setLoading(false);
      setError(null);
    }, 4000);
  };

  usePolling(fakeFunction, loading);

  if (!subscriptionId || (subscriptionType !== "cloud" && subscriptionType !== "selfhosted")) {
    return <NotFoundPage />;
  }
  if (!license && subscriptionType === "selfhosted") return <NotFoundPage />;
  if (!cloudSubscription && subscriptionType === "cloud") return <NotFoundPage />;

  /*@ts-expect-error cloudSubscription is not undefined*/
  const cloudInstanceLink = `https://${getHostname(cloudSubscription.dnsPrefix)}`;

  return (
    <Box paddingX={14} paddingTop={5} paddingBottom={16}>
      <Stack spacing={2}>
        <TentacleHeartIcon />
        <Typography variant={"h5"} fontWeight={"700"}>
          Order complete, thank you for subscribing
        </Typography>
        <Box>
          <Typography>
            An email was sent to <Link href={`mailto:${userInfo?.email}`}>{userInfo?.email}</Link> containing the
            receipt and invoice for your purchase.
          </Typography>
        </Box>
        {loading && (
          <>
            <Box>
              <Stack direction="row" spacing={1}>
                <Typography fontWeight={700}>Your Octopus is being setup</Typography>
                <Typography fontSize={"medium"}>– this should take less than a minute.</Typography>
              </Stack>
            </Box>
            <Stack direction="row" spacing={1}>
              <CircularProgress size={25} />
              <Typography color={"#68778D"}>Loading Octopus</Typography>
            </Stack>
          </>
        )}
        {!loading && !error && subscriptionType === "cloud" && (
          <Box>
            <Stack direction="column" spacing={1}>
              <Typography fontWeight={700} fontSize={"large"}>
                Your Octopus is ready!
              </Typography>
              <CheckoutPrimaryButton href={cloudInstanceLink} sx={{ width: "200px" }} endIcon={<OpenInNew />}>
                Check out Octopus
              </CheckoutPrimaryButton>
            </Stack>
          </Box>
        )}
        {!loading && !error && subscriptionType === "selfhosted" && (
          <Box>
            <Typography fontWeight={700} fontSize={"large"}>
              License Key
            </Typography>
            <Typography sx={{ marginBottom: "10px" }}>
              This information is also available on the{" "}
              <RouterLink to={ServerLicensesRoutesMap.detail(subscriptionId).root}>Overview</RouterLink> page.
            </Typography>
            {/*TODO: Get the actual updated server license xml after fulfilled, the below is just the old license*/}
            {/*@ts-expect-error serverLicense is not undefined*/}
            {canViewLicenseKeyXml && <SelfFetchingLicenseKeyView serverLicenseId={license?.id} />}
          </Box>
        )}
      </Stack>
    </Box>
  );
}
