import React from "react";
import type { ReactElement } from "react";
import type { AutocompleteChangeDetails, AutocompleteChangeReason } from "@mui/material";
import { Stack } from "@mui/material";
import { useCountries } from "contexts/CountriesProvider/CountriesProvider";
import type { DropdownAutocompleteOption } from "./CheckoutDropdownInput";
import { CheckoutFormField } from "./CheckoutFormField";

export interface AutocompleteAddressValue {
  countryCode: string;
  countrySubdivision: string;
  countrySubdivisionName: string;
  municipality: string;
  postalCode: string | null;
  streetName: string;
  streetNumber: string | null;
  freeFormAddress: string;
}

type OnInput = React.FormEventHandler<unknown>;

type OnChange = (
  event: React.SyntheticEvent<Element, Event>,
  value: unknown | AutocompleteAddressValue,
  reason: AutocompleteChangeReason,
  details?: AutocompleteChangeDetails<unknown> | undefined
) => void;

interface AddressSetters {
  setCountry?: (value: DropdownAutocompleteOption | null) => void;
  setCity?: (value: string) => void;
  setState?: (value: DropdownAutocompleteOption | string) => void;
  setPostcode?: (value: string | null) => void;
  setAddress?: (value: AutocompleteAddressValue | null) => void;
}

export interface CheckoutAddressFieldProps extends AddressSetters {
  countryInput?: ReactElement;
  renderStreetAddressInput: ({ onChange }: { onChange?: OnChange }) => ReactElement;
  cityInput?: ReactElement;
  stateInput?: ReactElement;
  postcodeInput?: ReactElement;
  onSelectAddress?: (value: unknown, setters?: AddressSetters) => void;
  errors?: {
    country?: { type: string; message?: string };
    streetAddress?: { type: string; message?: string };
    city?: { type: string; message?: string };
    state?: { type: string; message?: string };
    postcode?: { type: string; message?: string };
  };
}

const isAutocompleteAddressValue = (value: unknown): value is AutocompleteAddressValue => {
  return (
    typeof value === "object" &&
    value !== null &&
    ("countryCode" in value ||
      "countrySubdivisionName" in value ||
      "municipality" in value ||
      "postalCode" in value ||
      "streetName" in value ||
      "streetNumber" in value)
  );
};

export function CheckoutAddressField(props: CheckoutAddressFieldProps) {
  const { countries } = useCountries();

  const mapCountryCodeToOption = (val: string | undefined) => {
    return countries.find((item) => item.twoDigitIsoCode === val) ?? undefined;
  };

  const onSelectAddressDefault = (
    value: unknown | { label: string; value: AutocompleteAddressValue },
    setters?: AddressSetters
  ) => {
    if (typeof value === "object" && value !== null && "value" in value && value.value !== null) {
      const v = value.value;
      if (isAutocompleteAddressValue(v)) {
        setters?.setAddress && setters?.setAddress(v ?? null);
        const country = mapCountryCodeToOption(v.countryCode);
        const countryOption = country ? { label: country.name, value: country.twoDigitIsoCode } : null;
        setters?.setCountry && setters?.setCountry(countryOption);
        setters?.setCity && setters?.setCity(v.municipality ?? "");

        const state = country?.states.find((state) => state.twoDigitIsoCode === v.countrySubdivision);
        const stateOption = state ? { label: state.name, value: state.twoDigitIsoCode } : v.countrySubdivision ?? "";

        setters?.setState && setters?.setState(stateOption);
        setters?.setPostcode && setters?.setPostcode(v.postalCode ?? "");
      }
    }
  };

  const {
    countryInput,
    renderStreetAddressInput,
    cityInput,
    stateInput,
    postcodeInput,
    onSelectAddress = onSelectAddressDefault,
    errors,
  } = props;

  const setters = {
    setAddress: props.setAddress,
    setCountry: props.setCountry,
    setCity: props.setCity,
    setState: props.setState,
    setPostcode: props.setPostcode,
  };

  const onChange: OnChange = (event, value, reason, details) => {
    if (reason === "selectOption" && onSelectAddress) {
      onSelectAddress(value, setters);
    }
  };

  const streetAddressInput = renderStreetAddressInput({ onChange });

  return (
    <Stack spacing={2.5} flexGrow={1}>
      {countryInput && (
        <CheckoutFormField labelText={"Country"} input={countryInput} errorMessage={errors?.country?.message} />
      )}
      <CheckoutFormField
        labelText={"Address"}
        input={streetAddressInput}
        errorMessage={errors?.streetAddress?.message}
      />
      <Stack direction="row" spacing={2.5}>
        {cityInput && (
          <CheckoutFormField
            labelText={"City"}
            input={cityInput}
            errorMessage={errors?.city?.message}
            sx={{ flexGrow: 1 }}
          />
        )}
        {stateInput && (
          <CheckoutFormField
            labelText={"Province / State"}
            input={stateInput}
            errorMessage={errors?.state?.message}
            sx={{ flexGrow: 1 }}
          />
        )}
      </Stack>
      <Stack direction="row" spacing={2.5}>
        {postcodeInput && (
          <CheckoutFormField
            labelText={"Postcode / ZIP code"}
            input={postcodeInput}
            errorMessage={errors?.postcode?.message}
            sx={{ width: "calc(50% - 10px)" }}
          />
        )}
      </Stack>
    </Stack>
  );
}
