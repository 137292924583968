import type { DateTime } from "luxon";
import type { CheckoutSessionDto } from "client/api/CheckoutSessionDto";
import type { CustomerPortalSessionDto } from "client/api/CloudSubscriptionApi";
import type { ListQuery } from "client/api/common/ListQuery";
import axiosClient from "../axios-client";
import type { IntLimitFeatureDto } from "./FeatureApi";
import type { UpgradePlan } from "./UpgradePlan";
import type { ListEntitiesResponse } from "./common/ListEntitiesResponse";
import type { SubscriptionGroupDto } from "./common/SubscriptionGroupDto";

export interface ServerLicenseDto {
  id: string;
  friendlyName?: string;
  serial: string;
  serialHash: string;
  validFromDate: DateTime;
  maintenanceExpiresDate: DateTime;
  expiryDate: DateTime;
  tenantLimit: IntLimitFeatureDto;
  machineLimit: IntLimitFeatureDto;
  projectLimit?: IntLimitFeatureDto;
  userLimit?: IntLimitFeatureDto;
  taskCap?: number;
  workerLimit?: IntLimitFeatureDto;
  spaceLimit?: IntLimitFeatureDto;
  nodeLimit?: IntLimitFeatureDto;
  restrictedPermissions?: boolean;
  serviceNowFeatureExpiryDate: DateTime;
  spaceLevelInsightsFeatureExpiryDate: DateTime;
  auditLogFeedsFeatureExpiryDate: DateTime;
  jiraServiceManagementFeatureExpiryDate: DateTime;
  subscriptionGroup?: SubscriptionGroupDto;
  createdDate: DateTime;
  licensingChannel: ServerLicensingChannel;
  isFulfilled: boolean;
}

export type ServerLicensingChannel =
  | SalesforceContractedServerLicensingChannelDto
  | StripeSubscriptionServerLicensingChannelDto
  | TemporaryServerLicensingChannelDto
  | TrialServerLicensingChannelDto;

export type ServerLicensingChannelTypes = (typeof ServerLicensingChannelTypesArr)[number];

export const ServerLicensingChannelTypesKeys = {
  Temporary: "TemporaryServerLicensingChannelDto",
  Trial: "TrialServerLicensingChannelDto",
  Salesforce: "SalesforceContractedServerLicensingChannelDto",
  Stripe: "StripeSubscriptionServerLicensingChannelDto",
  Zuora: "ZuoraServerLicensingChannelDto",
} as const;

export const ServerLicensingChannelTypesArr = Object.values(ServerLicensingChannelTypesKeys);

export interface ServerLicensingChannelBase {
  id: string;
  channelType: ServerLicensingChannelTypes;
}

export interface TemporaryServerLicensingChannelDto extends ServerLicensingChannelBase {
  id: string;
  channelType: typeof ServerLicensingChannelTypesKeys.Temporary;
  createdDate: DateTime;
  expiryDate: DateTime;
}

export interface TrialServerLicensingChannelDto extends ServerLicensingChannelBase {
  id: string;
  channelType: typeof ServerLicensingChannelTypesKeys.Trial;
  trialId: string;
  createdDate: DateTime;
  expiryDate: DateTime;
}

export interface SalesforceContractedServerLicensingChannelDto extends ServerLicensingChannelBase {
  id: string;
  channelType: typeof ServerLicensingChannelTypesKeys.Salesforce;
  expiryDate: DateTime;
  salesforceContractId: string;
}

export interface StripeSubscriptionServerLicensingChannelDto extends ServerLicensingChannelBase {
  id: string;
  channelType: typeof ServerLicensingChannelTypesKeys.Stripe;
  stripeId: string;
  customerId: string;
  status: "active" | "canceled" | "incomplete" | "incomplete_expired" | "trialing" | "unpaid" | "past_due";
  nextRenewalDate: DateTime;
  cancelAtPeriodEnd: boolean;
  freemium: boolean;
  priceCode: string | undefined;
  productName: string | undefined;
  outOfOrderTimestamp: DateTime;
}

export function isStripeServerLicenseChannel(
  licensingChannel: ServerLicensingChannel
): licensingChannel is StripeSubscriptionServerLicensingChannelDto {
  return licensingChannel.channelType === ServerLicensingChannelTypesKeys.Stripe;
}

export function isPaymentOverdue(serverLicense: ServerLicenseDto): boolean {
  return isStripeServerLicenseChannel(serverLicense.licensingChannel)
    ? serverLicense.licensingChannel.status === "past_due" || serverLicense.licensingChannel.status === "unpaid"
    : false;
}

const BASE_URL = "server-licenses";

export type GetServerLicensesResponse = ListEntitiesResponse<ServerLicenseDto>;

export const getServerLicenses = async (query: ListQuery): Promise<GetServerLicensesResponse> => {
  const response = await axiosClient.get<GetServerLicensesResponse>(BASE_URL, {
    params: query,
  });
  return response.data;
};

export const getServerLicenseById = async (serverLicenseId: string): Promise<ServerLicenseDto> => {
  const response = await axiosClient.get<ServerLicenseDto>(`${BASE_URL}/${serverLicenseId}`);
  return response.data;
};

export const getServerLicenseBySerial = async (serialId: string): Promise<ServerLicenseDto> => {
  const response = await axiosClient.get<ServerLicenseDto>(`${BASE_URL}/serial/${serialId}`);
  return response.data;
};

export interface LicenseXmlDto {
  id: string;
  licenseXml: string;
}

export const getLicenseXmlById = async (licenseId: string): Promise<LicenseXmlDto> => {
  const response = await axiosClient.get<LicenseXmlDto>(`${BASE_URL}/${licenseId}/license-xml`);
  return response.data;
};

export const checkServerLicenseFulfilled = async (licenseId: string): Promise<boolean> => {
  const response = await axiosClient.get<boolean>(`${BASE_URL}/${licenseId}/fulfilled`);
  return response.data;
};

export const createServerLicenseCustomerPortalUrl = async (
  serverLicenseId: string,
  returnUrl: string
): Promise<CustomerPortalSessionDto> => {
  const response = await axiosClient.post<CustomerPortalSessionDto>(
    `${BASE_URL}/${serverLicenseId}/customer-portal-sessions`,
    {
      returnUrl,
    }
  );
  return response.data;
};

/*
   public enum MachineLimitUpdateTiming
    {
        Immediately,
        NextRenewal
    }
 */

export type MachineLimitUpdateTiming = "Immediately" | "NextRenewal";

export const updateMachineLimit = async (
  serverLicenseId: string,
  machineLimit: number,
  updatesAt: MachineLimitUpdateTiming,
  previewDate?: DateTime
): Promise<ServerLicenseDto> => {
  const response = await axiosClient.put<ServerLicenseDto>(`${BASE_URL}/${serverLicenseId}/machine-limit`, {
    id: serverLicenseId,
    machineLimit,
    updatesAt,
    previewDate: previewDate?.toUTC()?.toISODate(),
  });

  return response.data;
};

export interface PreviewServerLicenseMachineLimitUpdateResponse {
  currency: string;
  amountDue: number;
  tax?: number;
  previewDate: DateTime;
}

export const previewMachineLimitUpdate = async (
  serverLicenseId: string,
  machineLimit: number,
  updatesAt: MachineLimitUpdateTiming,
  previewNoChange: boolean
): Promise<PreviewServerLicenseMachineLimitUpdateResponse | undefined> => {
  const response = await axiosClient.post<PreviewServerLicenseMachineLimitUpdateResponse>(
    `${BASE_URL}/${serverLicenseId}/machine-limit/preview`,
    {
      machineLimit,
      updatesAt,
      previewNoChange,
    }
  );

  return response.data;
};

export const createServerLicenseCheckoutUrl = async (
  serverLicenseId: string,
  targetPlan: UpgradePlan
): Promise<CheckoutSessionDto> => {
  const response = await axiosClient.post<CheckoutSessionDto>(`${BASE_URL}/${serverLicenseId}/checkout-sessions`, {
    targetPlan,
  });
  return response.data;
};

export const cancelServerLicense = async (serverLicense: ServerLicenseDto): Promise<ServerLicenseDto> => {
  const response = await axiosClient.delete<ServerLicenseDto>(`${BASE_URL}/${serverLicense.id}`);
  return response.data;
};

export const changeStripeProduct = async (serverLicenseId: string, targetPlan: UpgradePlan): Promise<void> => {
  await axiosClient.put(`${BASE_URL}/${serverLicenseId}/licensing-channel/product`, {
    serverLicenseId,
    targetPlan,
  });
};
