import React, { useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { DateText } from "@cpt/components";
import { IsSubscribedOrLapsed } from "utils/CloudSubscription/CloudSubscriptionStatus";
import type {
  CloudSubscriptionDto,
  StripeSubscriptionCloudSubscriptionLicensingChannelDto,
} from "client/api/CloudSubscriptionApi";
import { UpgradePlan } from "client/api/UpgradePlan";
import { ChangePlanFinanceLine } from "components/ChangePlanFinanceLine/ChangePlanFinanceLine";
import { GridDivider } from "components/GridDivider/GridDivider";
import { LicensedEntitlementsView } from "components/LicensedEntitlementsView/LicensedEntitlementsView";
import { LinkButton } from "components/LinkButton/LinkButton";
import { OrdersFinanceLine } from "components/OrdersFinanceLine/OrdersFinanceLine";
import { RouterLink } from "components/RouterLink/RouterLink";
import { themeTokens } from "components/Theme/theme";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { CloudSubscriptionsRoutesMap } from "areas/cloudSubscriptions/CloudSubscriptionRoutesMap";
import { CustomerPortalLink } from "areas/cloudSubscriptions/components/CustomerPortalLink";
import { CloudSubscriptionCancelPlanDialog } from "areas/cloudSubscriptions/detail/components/finance/CloudSubscriptionCancelPlanDialog";
import { CustomerSpecifiedMachineLimitLine } from "areas/cloudSubscriptions/detail/components/finance/CustomerSpecifiedMachineLimitLine";
import type { LicensingChannelViewProps } from "areas/cloudSubscriptions/detail/components/finance/LicensingChannelViewProps";

type EntitlementViewProps = { cloudSubscription: CloudSubscriptionDto };
function EntitlementView(props: EntitlementViewProps) {
  const { cloudSubscription } = props;
  return <LicensedEntitlementsView license={cloudSubscription} />;
}

export function StripeSubscriptionLicensingChannelView(
  props: LicensingChannelViewProps<StripeSubscriptionCloudSubscriptionLicensingChannelDto>
) {
  const { cloudSubscription, channel } = props;
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);

  const stripeSubscriptionCancelled = channel.status == "canceled";
  //if the status is canceled, show when it was canceled
  const planStatusPrefix =
    channel.status === "canceled" ? "Cancelled on" : channel.cancelAtPeriodEnd ? "Cancels on" : "Renews on";
  const isCancelledOrCancelling = channel.status === "canceled" || channel.cancelAtPeriodEnd;
  const manageButtonText = isCancelledOrCancelling ? "Resubscribe" : "Manage subscription";
  const isCommunity = channel.productName?.toLowerCase().includes("community");
  const isSubscriptionPtm = [
    UpgradePlan.Cloud_Annually_Starter,
    UpgradePlan.Cloud_Annually_Professional,
    UpgradePlan.Cloud_Annually_Enterprise,
  ].some((val) => val === channel.priceCode);
  const showCustomStripeControls =
    IsSubscribedOrLapsed(cloudSubscription.cloudSubscriptionStatus) && !channel.cancelAtPeriodEnd;
  const isCanceledNonPtm = !isSubscriptionPtm && (channel.status === "canceled" || channel.cancelAtPeriodEnd);
  const showResubscribe = !isCanceledNonPtm;

  return (
    <>
      <>
        <Grid item sm={3} sx={{ padding: 3 }}>
          <Typography fontWeight={700}>Current plan</Typography>
        </Grid>
        <Grid item sm={6} sx={{ padding: 3 }}>
          <Stack spacing={0}>
            <Typography>{channel.productName}</Typography>
            <Typography sx={{ color: themeTokens.color.text.secondary }}>
              {planStatusPrefix} <DateText date={channel.nextRenewalDate} />
            </Typography>
          </Stack>
        </Grid>
        <PolicyAuthorized
          assetId={cloudSubscription?.id}
          subscriptionGroupId={cloudSubscription?.subscriptionGroup?.id}
          permission={RbacPermissions.CloudSubscription.ViewBilling}
        >
          <Grid item sm={3} sx={{ padding: 3 }}>
            <Stack spacing={1} sx={{ alignItems: "flex-end" }}>
              {showResubscribe && (
                <CustomerPortalLink
                  text={manageButtonText}
                  productType={"CloudSubscription"}
                  productId={cloudSubscription.id}
                />
              )}
              {showCustomStripeControls && (
                <PolicyAuthorized
                  permission={RbacPermissions.CloudSubscription.ManageBilling}
                  assetId={cloudSubscription?.id}
                  subscriptionGroupId={cloudSubscription?.subscriptionGroup?.id}
                >
                  <RouterLink to={CloudSubscriptionsRoutesMap.detail(cloudSubscription.id).upgrade}>
                    Change plan
                  </RouterLink>
                  {!isCancelledOrCancelling && (
                    <>
                      <LinkButton onClick={() => setShowCancelDialog(true)}>Cancel subscription</LinkButton>
                      <CloudSubscriptionCancelPlanDialog
                        open={showCancelDialog}
                        onClose={() => setShowCancelDialog(false)}
                        cloudSubscription={cloudSubscription}
                      >
                        <span>
                          If you cancel now, you can use your subscription until{" "}
                          <DateText date={channel.nextRenewalDate} fontWeight="bold" />. We'll then automatically
                          deactivate the plan and put secure backups of your data into cold-storage. After 90 days, your
                          data will be automatically deleted. If you want your data deleted sooner, email{" "}
                          <a href="mailto:support@octopus.com">support@octopus.com</a> after you've deactivated your
                          instance, and we'll delete your data immediately.
                        </span>
                      </CloudSubscriptionCancelPlanDialog>
                    </>
                  )}
                </PolicyAuthorized>
              )}
            </Stack>
          </Grid>
        </PolicyAuthorized>
      </>
      <GridDivider />
      {!showCustomStripeControls && !stripeSubscriptionCancelled && (
        <>
          <ChangePlanFinanceLine />
          <GridDivider />
        </>
      )}
      {!isCommunity && !isSubscriptionPtm && (
        <>
          <CustomerSpecifiedMachineLimitLine
            cloudSubscription={cloudSubscription}
            editable={channel.status !== "canceled"}
          />
          <GridDivider />
        </>
      )}
      {isCommunity && (
        <>
          <EntitlementView cloudSubscription={cloudSubscription} />
          <GridDivider />
        </>
      )}
      <OrdersFinanceLine cloudSubscription={cloudSubscription} />
    </>
  );
}
