import React, { useState } from "react";
import type { SxProps, Theme } from "@mui/material";
import { Box, Typography, Grid, Stack } from "@mui/material";
import { useErrorBoundary } from "react-error-boundary";
import type { FormState } from "react-hook-form";
import { FormProvider, type FieldErrors, useForm } from "react-hook-form";
import { useCountries } from "contexts/CountriesProvider/CountriesProvider";
import type { State } from "client/api/CountriesApi";
import type { OrderPreview } from "client/api/PurchaseApi";
import { CustomErrorAlert, ErrorMessageTryAgainOrContactSupport } from "components/alert/CustomErrorAlert";
import { CheckoutAddressField } from "areas/checkout/components/CheckoutAddressField";
import { CheckoutButtonLikeRadio } from "areas/checkout/components/CheckoutButtonLikeRadio";
import { CheckoutPrimaryButton, CheckoutSecondaryButton } from "areas/checkout/components/CheckoutButtons";
import { CheckoutDropdownInput } from "areas/checkout/components/CheckoutDropdownInput";
import type { DropdownAutocompleteOption } from "areas/checkout/components/CheckoutDropdownInput";
import { CheckoutFormField } from "areas/checkout/components/CheckoutFormField";
import { CheckoutStreetAddressInput } from "areas/checkout/components/CheckoutStreetAddressInput";
import { CheckoutTextInput } from "areas/checkout/components/CheckoutTextInput";
import { CheckoutStepLayout } from "areas/purchasing/components/CheckoutStepLayout";
import { CheckoutStepNames } from "../Checkout";

interface CheckoutPersonalDetailsStepProps {
  hasOrderPreviewError: boolean;
  initialData: CheckoutPersonalDetailsInputs;
  nextStep: keyof typeof CheckoutStepNames;
  onBack: () => void;
  onSubmit: (data: CheckoutPersonalDetailsInputs) => void;
  onAddressChanged: (data: CheckoutPersonalDetailsInputs) => void;
  orderSummary: React.ReactElement;
  sx?: SxProps<Theme>;
  orderPreview: OrderPreview | null;
}

export interface CheckoutBillingAddress {
  streetAddress: string;
  country: DropdownAutocompleteOption | null;
  city: string;
  state: DropdownAutocompleteOption | string | null;
  postcode: string;
}

export interface BillingContactBase {
  firstName: string;
  lastName: string;
  email: string;
}

export type IndividualBillingContact = BillingContactBase & CheckoutBillingAddress;

export type CompanyBillingContact = BillingContactBase;

export type CompanyContact = {
  name: string;
} & CheckoutBillingAddress;

export type PurchasingAs = "company" | "individual" | null;

export type CheckoutCompanyForm = {
  purchasingAs: "company";
  billingContact: CompanyBillingContact;
  companyContact: CompanyContact;
  taxId?: string;
};

export type CheckoutIndividualForm = {
  purchasingAs: "individual";
  billingContact: IndividualBillingContact;
};

export type CheckoutPersonalDetailsInputs =
  | CheckoutCompanyForm
  | CheckoutIndividualForm
  | ({
      purchasingAs: null;
    } & (Omit<CheckoutCompanyForm, "purchasingAs"> | Omit<CheckoutIndividualForm, "purchasingAs">));

export const isCheckoutCompanyForm = (
  formData: FormState<CheckoutPersonalDetailsInputs>,
  purchasingAs: PurchasingAs
): formData is FormState<CheckoutCompanyForm> => {
  return purchasingAs === "company";
};

export const isCheckoutIndividualForm = (
  formData: FormState<CheckoutPersonalDetailsInputs>,
  purchasingAs: PurchasingAs
): formData is FormState<CheckoutIndividualForm> => {
  return purchasingAs === "individual";
};

const purchasingAsFieldOptions = { required: "Please select who you are purchasing as" };
const addressFieldOptions = {
  country: {
    required: {
      value: true,
      message: "Please select a country",
    },
  },
  streetAddress: {
    required: {
      value: true,
      message: "Please enter a street address",
    },
  },
  city: {
    required: {
      value: true,
      message: "Please enter a city",
    },
  },
  state: {
    required: {
      value: true,
      message: "Please enter a province / state",
    },
  },
  postcode: {
    required: {
      value: true,
      message: "Please enter a post or ZIP code",
    },
  },
};

type ContactType = "billingContact" | "companyContact";
const mapErrors = (errors: FieldErrors, contact: ContactType) => {
  const obj: { [index: string]: { message: string } } = {};
  const contactErrors = errors[contact];

  if (contactErrors) {
    for (const k in contactErrors) {
      // @ts-expect-error want string but is weird union type
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const message = contactErrors[k]?.message as string;
      obj[k] = { message };
    }
  }

  return obj;
};

export function CheckoutPersonalDetailsStep(props: CheckoutPersonalDetailsStepProps) {
  const {
    onBack,
    onSubmit,
    onAddressChanged,
    initialData,
    nextStep,
    orderSummary,
    hasOrderPreviewError,
    sx,
    orderPreview,
  } = props;

  const { showBoundary } = useErrorBoundary();
  const { countries } = useCountries();
  const [billingContactStates, setBillingContactStates] = useState<State[]>([]);
  const [companyContactStates, setCompanyContactStates] = useState<State[]>([]);

  const getStates = (countryCode: string | undefined): State[] => {
    if (!countryCode) return [];
    const matchingCountry = countries.find((country) => country.twoDigitIsoCode === countryCode);
    return matchingCountry?.states ?? [];
  };

  const formController = useForm<CheckoutPersonalDetailsInputs>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: initialData,
  });

  const { register, unregister, formState, watch, setValue, getValues } = formController;

  const billingContactCountryCode = watch("billingContact.country.value");
  const companyCountryCode = watch("companyContact.country.value");
  const purchasingAs = watch("purchasingAs");
  const isPurchasingAsBusiness = watch("purchasingAs") === "company";

  const showTaxIdField = () => isPurchasingAsBusiness && companyCountryCode === "AU";

  const requireAbnField = () => {
    return (orderPreview?.totalAmount ?? 0) >= 1000;
  };

  const handlePurchasingAs = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value != "company" && value != "individual") {
      const error = new TypeError("Attempted to set purchasingAs to an invalid value");
      showBoundary(error);
      return;
    }
    const isPurchasingAsBusiness = value === "company";
    setValue("purchasingAs", value);
    onAddressChanged(getValues());
    if (isPurchasingAsBusiness) {
      unregisterIndividual();
      registerCompany();
    }
    if (!isPurchasingAsBusiness) {
      unregisterCompany();
      registerIndividual();
    }
  };

  const registerCompany = () => {
    register("companyContact.country");
    register("companyContact.name");
    register("taxId");
    register("companyContact.streetAddress");
    register("companyContact.city");
    register("companyContact.state");
    register("companyContact.postcode");
    register("billingContact.firstName");
    register("billingContact.lastName");
    register("billingContact.email");
  };

  const registerIndividual = () => {
    register("billingContact.country");
    register("billingContact.firstName");
    register("billingContact.lastName");
    register("billingContact.email");
    register("billingContact.streetAddress");
    register("billingContact.city");
    register("billingContact.state");
    register("billingContact.postcode");
  };

  const unregisterCompany = () => {
    unregister("companyContact", { keepDefaultValue: true });
    unregister("taxId", { keepDefaultValue: true });
    unregister("billingContact.firstName", { keepValue: true });
    unregister("billingContact.lastName", { keepValue: true });
    unregister("billingContact.email", { keepValue: true });
  };

  const unregisterIndividual = () => {
    // NOTE: unregisters fields specifically so validation focus order is correct when swapping between company and individual, also to retain shared field values
    unregister("billingContact.firstName", { keepValue: true });
    unregister("billingContact.lastName", { keepValue: true });
    unregister("billingContact.email", { keepValue: true });
    unregister("billingContact.streetAddress", { keepDefaultValue: true });
    unregister("billingContact.city", { keepDefaultValue: true });
    unregister("billingContact.state", { keepDefaultValue: true });
    unregister("billingContact.postcode", { keepDefaultValue: true });
  };

  // Always register purchasing as. Other fields are registered based on it's value.
  register("purchasingAs");

  const hasCountry = (() => {
    if (isCheckoutCompanyForm(formState, purchasingAs)) {
      return !!companyCountryCode;
    } else if (isCheckoutIndividualForm(formState, purchasingAs)) {
      return !!billingContactCountryCode;
    }
    return false;
  })();
  const hideForm = orderSummary.props.orderPreview === null && hasOrderPreviewError;
  const stepTestId = "checkout-personal-details-step";

  if (hideForm) {
    return (
      <Box data-testid={stepTestId}>
        <CustomErrorAlert message={<ErrorMessageTryAgainOrContactSupport />} />
        <Box padding={3}>
          <CheckoutSecondaryButton disableElevation variant="contained" onClick={onBack}>
            Return to select plan
          </CheckoutSecondaryButton>
        </Box>
      </Box>
    );
  } else {
    return (
      <CheckoutStepLayout orderSummary={orderSummary} sx={sx} testId={stepTestId}>
        <FormProvider {...formController}>
          <form onSubmit={formController.handleSubmit((data) => onSubmit(data))}>
            <Stack direction="column" spacing={4}>
              <Typography variant="h5" fontWeight="600">
                Upgrade your plan
              </Typography>
              <Stack spacing={2}>
                <Typography variant="h6">Purchasing as a</Typography>
                <CheckoutFormField
                  errorMessage={purchasingAs === null ? formState.errors?.purchasingAs?.message : undefined}
                  input={
                    <Stack spacing={1} direction={"row"}>
                      <CheckoutButtonLikeRadio
                        id="company"
                        value="company"
                        fullWidth
                        {...register("purchasingAs", purchasingAsFieldOptions)}
                        onChange={handlePurchasingAs}
                      >
                        Company
                      </CheckoutButtonLikeRadio>
                      <CheckoutButtonLikeRadio
                        id="individual"
                        value="individual"
                        fullWidth
                        {...register("purchasingAs", purchasingAsFieldOptions)}
                        onChange={handlePurchasingAs}
                      >
                        Individual
                      </CheckoutButtonLikeRadio>
                    </Stack>
                  }
                />
              </Stack>
              {isCheckoutCompanyForm(formState, purchasingAs) && (
                <Stack spacing={3} data-testid="checkout-company-details">
                  <Typography variant="h6" sx={{ "&&": { marginBottom: "-0.5rem" } }}>
                    Company details
                  </Typography>
                  <CheckoutFormField
                    labelText="Country"
                    errorMessage={formState.errors?.companyContact?.country?.message}
                    input={
                      <CheckoutDropdownInput
                        id="companyContact.country"
                        options={countries?.map((country) => ({
                          label: country.name,
                          value: country.twoDigitIsoCode,
                        }))}
                        value={watch("companyContact.country")}
                        setOption={(value) => {
                          setValue("companyContact.country", value ?? null);
                          setValue("companyContact.state", null);
                          setCompanyContactStates(
                            getStates(typeof value?.value === "string" ? value.value : undefined)
                          );
                          onAddressChanged(getValues());
                        }}
                        isOptionEqualToValue="valueMatch"
                        error={!!formState.errors?.companyContact?.country}
                        placeholder="Please select a country"
                        InputProps={{
                          ...register("companyContact.country", addressFieldOptions.country),
                        }}
                      />
                    }
                  />
                  {hasCountry && (
                    <>
                      <CheckoutFormField
                        labelText="Company name"
                        errorMessage={formState.errors?.companyContact?.name?.message}
                        input={
                          <CheckoutTextInput
                            id="companyContact.name"
                            required={true}
                            error={!!formState.errors?.companyContact?.name}
                            onChange={(event) => setValue("companyContact.name", event.target.value)}
                            inputProps={{
                              ...register("companyContact.name", {
                                required: "Please enter company name",
                              }),
                              autoComplete: "organization",
                            }}
                          />
                        }
                      />
                      {showTaxIdField() && (
                        <CheckoutFormField
                          labelText="ABN"
                          errorMessage={formState.errors?.taxId?.message}
                          input={
                            <CheckoutTextInput
                              id="taxId"
                              required={requireAbnField()}
                              error={!!formState.errors?.taxId}
                              onChange={(event) => setValue("taxId", event.target.value)}
                              inputProps={{
                                ...register(
                                  "taxId",
                                  requireAbnField()
                                    ? {
                                        required: "Please enter ABN",
                                      }
                                    : {}
                                ),
                              }}
                            />
                          }
                        />
                      )}
                      <CheckoutAddressField
                        renderStreetAddressInput={(props) => (
                          <CheckoutStreetAddressInput
                            id="companyContact.streetAddress"
                            {...props}
                            countryCode={companyCountryCode}
                            onInputChange={(_event, value) => setValue("companyContact.streetAddress", value)}
                            error={!!formState.errors.companyContact?.streetAddress}
                            InputProps={{
                              ...register("companyContact.streetAddress", addressFieldOptions.streetAddress),
                            }}
                          />
                        )}
                        cityInput={
                          <CheckoutTextInput
                            id="companyContact.city"
                            required={addressFieldOptions.city.required.value}
                            error={!!formState.errors?.companyContact?.city}
                            onChange={(event) => setValue("companyContact.city", event.target.value)}
                            inputProps={{
                              ...register("companyContact.city", addressFieldOptions.city),
                              autoComplete: "address-level2",
                            }}
                          />
                        }
                        stateInput={
                          companyContactStates.length > 0 ? (
                            <CheckoutDropdownInput
                              id="companyContact.state"
                              options={companyContactStates?.map((state) => ({
                                label: state.name,
                                value: state.twoDigitIsoCode,
                              }))}
                              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                              value={watch("companyContact.state") as DropdownAutocompleteOption}
                              setOption={(value) => {
                                setValue("companyContact.state", value ?? null);
                              }}
                              isOptionEqualToValue="valueMatch"
                              error={!!formState.errors.companyContact?.state}
                              placeholder="Please select a state"
                              InputProps={{
                                ...register("companyContact.state", addressFieldOptions.state),
                                autoComplete: "address-level1",
                              }}
                            />
                          ) : (
                            <CheckoutTextInput
                              id="companyContact.state"
                              required={addressFieldOptions.state.required.value}
                              value={watch("companyContact.state") ?? ""}
                              onChange={(event) => {
                                setValue("companyContact.state", event.target.value);
                              }}
                              error={!!formState.errors.companyContact?.state}
                              inputProps={{
                                ...register("companyContact.state", addressFieldOptions.state),
                                autoComplete: "address-level1",
                              }}
                            />
                          )
                        }
                        postcodeInput={
                          <CheckoutTextInput
                            id="companyContact.postcode"
                            required={addressFieldOptions.postcode.required.value}
                            onChange={(event) => {
                              setValue("companyContact.postcode", event.target.value);
                              onAddressChanged(getValues());
                            }}
                            error={!!formState.errors?.companyContact?.postcode}
                            inputProps={{
                              ...register("companyContact.postcode", addressFieldOptions.postcode),
                              autoComplete: "postal-code",
                            }}
                          />
                        }
                        setCountry={(value) => {
                          setValue("companyContact.country", value ?? null);
                          onAddressChanged(getValues());
                        }}
                        setCity={(value) => setValue("companyContact.city", value)}
                        setState={(value) => setValue("companyContact.state", value)}
                        setPostcode={(value) => setValue("companyContact.postcode", value ?? "")}
                        errors={mapErrors(formState.errors, "companyContact")}
                      />
                    </>
                  )}
                </Stack>
              )}
              {((isCheckoutCompanyForm(formState, purchasingAs) && hasCountry) ||
                isCheckoutIndividualForm(formState, purchasingAs)) && (
                <Stack spacing={3} data-testid="checkout-billing-contact-details">
                  <Typography variant="h6" sx={{ "&&": { marginBottom: "-0.5rem" } }}>
                    Billing contact
                  </Typography>
                  {isCheckoutIndividualForm(formState, purchasingAs) && (
                    <CheckoutFormField
                      labelText="Country"
                      errorMessage={formState.errors?.billingContact?.country?.message}
                      input={
                        <CheckoutDropdownInput
                          id="billingContact.country"
                          options={countries?.map((country) => ({
                            label: country.name,
                            value: country.twoDigitIsoCode,
                          }))}
                          value={watch("billingContact.country")}
                          setOption={(value) => {
                            setValue("billingContact.country", value ?? null);
                            setValue("billingContact.state", null);
                            setBillingContactStates(
                              getStates(typeof value?.value === "string" ? value.value : undefined)
                            );
                            onAddressChanged(getValues());
                          }}
                          isOptionEqualToValue="valueMatch"
                          error={!!formState.errors.billingContact?.country}
                          placeholder="Please select a country"
                          InputProps={{
                            ...register("billingContact.country", addressFieldOptions.country),
                          }}
                        />
                      }
                    />
                  )}
                  {hasCountry && (
                    <>
                      <Stack direction="row" spacing={2.5}>
                        <CheckoutFormField
                          labelText="First name"
                          errorMessage={formState.errors?.billingContact?.firstName?.message}
                          input={
                            <CheckoutTextInput
                              id="billingContact.firstName"
                              required={true}
                              error={!!formState.errors?.billingContact?.firstName}
                              inputProps={{
                                ...register("billingContact.firstName", {
                                  required: "Please enter first name",
                                }),
                                autoComplete: "given-name",
                              }}
                            />
                          }
                          sx={{ flexGrow: 1 }}
                        />
                        <CheckoutFormField
                          labelText="Last name"
                          errorMessage={formState.errors?.billingContact?.lastName?.message}
                          input={
                            <CheckoutTextInput
                              id="billingContact.lastName"
                              required={true}
                              error={!!formState.errors?.billingContact?.lastName}
                              inputProps={{
                                ...register("billingContact.lastName", {
                                  required: "Please enter last name",
                                }),
                                autoComplete: "family-name",
                              }}
                            />
                          }
                          sx={{ flexGrow: 1 }}
                        />
                      </Stack>
                      <CheckoutFormField
                        labelText="Email"
                        errorMessage={formState.errors?.billingContact?.email?.message}
                        input={
                          <CheckoutTextInput
                            id="billingContact.email"
                            required={true}
                            error={!!formState.errors?.billingContact?.email}
                            inputProps={{
                              ...register("billingContact.email", {
                                required: "Please enter an email address",
                              }),
                              autoComplete: "email",
                            }}
                          />
                        }
                        sx={{ flexGrow: 1 }}
                      />
                      {isCheckoutIndividualForm(formState, purchasingAs) && (
                        <CheckoutAddressField
                          renderStreetAddressInput={(props) => (
                            <CheckoutStreetAddressInput
                              {...props}
                              id="billingContact.streetAddress"
                              countryCode={billingContactCountryCode}
                              onInputChange={(_event, value) => setValue("billingContact.streetAddress", value)}
                              error={!!formState.errors.billingContact?.streetAddress}
                              InputProps={{
                                ...register("billingContact.streetAddress", addressFieldOptions.streetAddress),
                              }}
                            />
                          )}
                          cityInput={
                            <CheckoutTextInput
                              id="billingContact.city"
                              required={addressFieldOptions.city.required.value}
                              onChange={(event) => {
                                setValue("billingContact.city", event.target.value);
                              }}
                              error={!!formState.errors.billingContact?.city}
                              inputProps={{
                                ...register("billingContact.city", addressFieldOptions.city),
                                autoComplete: "address-level2",
                              }}
                            />
                          }
                          stateInput={
                            billingContactStates.length > 0 ? (
                              <CheckoutDropdownInput
                                id="billingContact.state"
                                options={billingContactStates?.map((state) => ({
                                  label: state.name,
                                  value: state.twoDigitIsoCode,
                                }))}
                                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                                value={watch("billingContact.state") as DropdownAutocompleteOption}
                                setOption={(value) => {
                                  setValue("billingContact.state", value ?? null);
                                  onAddressChanged(getValues());
                                }}
                                isOptionEqualToValue="valueMatch"
                                error={!!formState.errors.billingContact?.state}
                                placeholder="Please select a state"
                                InputProps={{
                                  ...register("billingContact.state", addressFieldOptions.state),
                                  autoComplete: "address-level1",
                                }}
                              />
                            ) : (
                              <CheckoutTextInput
                                id="billingContact.state"
                                required={addressFieldOptions.state.required.value}
                                value={watch("billingContact.state") ?? ""}
                                onChange={(event) => {
                                  setValue("billingContact.state", event.target.value);
                                  onAddressChanged(getValues());
                                }}
                                error={!!formState.errors.billingContact?.state}
                                inputProps={{
                                  ...register("billingContact.state", addressFieldOptions.state),
                                  autoComplete: "address-level1",
                                }}
                              />
                            )
                          }
                          postcodeInput={
                            <CheckoutTextInput
                              id="billingContact.postcode"
                              required={addressFieldOptions.postcode.required.value}
                              onChange={(event) => {
                                setValue("billingContact.postcode", event.target.value);
                                onAddressChanged(getValues());
                              }}
                              error={!!formState.errors.billingContact?.postcode}
                              inputProps={{
                                ...register("billingContact.postcode", addressFieldOptions.postcode),
                                autoComplete: "postal-code",
                              }}
                            />
                          }
                          setCountry={(value) => {
                            setValue("billingContact.country", value ?? null);
                            onAddressChanged(getValues());
                          }}
                          setCity={(value) => setValue("billingContact.city", value)}
                          setState={(value) => setValue("billingContact.state", value)}
                          setPostcode={(value) => setValue("billingContact.postcode", value ?? "")}
                          errors={mapErrors(formState.errors, "billingContact")}
                        />
                      )}
                    </>
                  )}
                </Stack>
              )}
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item md={6}>
                  <CheckoutSecondaryButton disableElevation variant="contained" onClick={onBack}>
                    Return to select plan
                  </CheckoutSecondaryButton>
                </Grid>
                <Grid item md={6} textAlign={"right"}>
                  <CheckoutPrimaryButton disableElevation variant="contained" type="submit">
                    {nextStep === CheckoutStepNames.Payment ? <>Continue to payment</> : <>Continue to contact Sales</>}
                  </CheckoutPrimaryButton>
                </Grid>
              </Grid>
            </Stack>
          </form>
        </FormProvider>
      </CheckoutStepLayout>
    );
  }
}
